import { configureStore } from '@reduxjs/toolkit';

import { adminReducer } from './slice/adminSlice';
import { configReducer } from './slice/configSlice';
import { gymReducer } from './slice/gymSlice';
import { logReducer } from './slice/logSlice';
import { salesSummariesReducer } from './slice/salesSummariesSlice';
import { userReducer } from './slice/userSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    log: logReducer,
    config: configReducer,
    salesSummaries: salesSummariesReducer,
    gym: gymReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
