var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d393d61cd66d80c8a9ec3cb59a5210001515ea77"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://33fbf25bd8584ead2bc16108dce35e7b@o4505233006985216.ingest.sentry.io/4505667688202240',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  // tracesは従量課金制なので、一旦本番だけ100%(1.0)、それ以外は0%に設定
  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 0.3 : 0,
});
