import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import AnalysisSvg from '/public/gymx/analysis_1.svg';
import AnalysisPressedSvg from '/public/gymx/analysis_2.svg';
import EntranceLogsSvg from '/public/gymx/entrance_logs_1.svg';
import EntranceLogsPressedSvg from '/public/gymx/entrance_logs_2.svg';
import HomeSvg from '/public/gymx/home_1.svg';
import HomePressedSvg from '/public/gymx/home_2.svg';
import PaymentLogsSvg from '/public/gymx/payment_logs_1.svg';
import PaymentLogsPressedSvg from '/public/gymx/payment_logs_2.svg';
import SettingSvg from '/public/gymx/setting_1.svg';
import SettingPressedSvg from '/public/gymx/setting_2.svg';
import UsersSvg from '/public/gymx/users_1.svg';
import UsersPressedSvg from '/public/gymx/users_2.svg';
import { useLogout } from '@/hooks/useAuth';
import useGym from '@/hooks/useGym';
import { authoritySelector } from '@/store/selector/adminSelector';
import { logSlice } from '@/store/slice/logSlice';
import { userSlice } from '@/store/slice/userSlice';

import { StoreSelector } from './StoreSelector';

export const SideMenu = () => {
  const [currentPath, setCurrentPath] = useState<string>('');

  const { selectedGym } = useGym();
  const { handleLogout } = useLogout();
  const authorityState = useSelector(authoritySelector);

  const router = useRouter();
  const dispatch = useDispatch();

  const isPressed = useCallback(
    (path?: string) => {
      const currentPathMenuName = currentPath.split('/')[2];
      return path === currentPathMenuName;
    },
    [currentPath],
  );

  const goToTop = () => {
    router.push(`/${selectedGym?.id}`);
    dispatch(userSlice.actions.reset());
    dispatch(logSlice.actions.reset());
  };

  const goToPage = (path: string) => {
    router.push(path);
  };

  useEffect(() => {
    //pathの監視
    setCurrentPath(router.pathname);
  }, [router.pathname]);

  return (
    <Container>
      <Title onClick={goToTop}>
        <img src="/gymx/title_logo.png" alt="title_logo" width={176} height={44} />
      </Title>
      <MenuList>
        <div>
          <MenuListItem isPressed={isPressed()} onClick={() => goToPage(`/${selectedGym?.id}`)}>
            <IconBox>{isPressed() ? <HomePressedSvg /> : <HomeSvg />}</IconBox>
            <span>ホーム</span>
          </MenuListItem>
          {authorityState.sale !== 0 && (
            <MenuListItem isPressed={isPressed('sales')} onClick={() => goToPage(`/${selectedGym?.id}/sales`)}>
              <IconBox>{isPressed('sales') ? <AnalysisPressedSvg /> : <AnalysisSvg />}</IconBox>
              <span>売上</span>
            </MenuListItem>
          )}
          {authorityState.userView !== 0 && (
            <MenuListItem isPressed={isPressed('users')} onClick={() => goToPage(`/${selectedGym?.id}/users`)}>
              <IconBox>{isPressed('users') ? <UsersPressedSvg /> : <UsersSvg />}</IconBox>
              <span>会員一覧</span>
            </MenuListItem>
          )}
          {authorityState.log !== 0 && (
            <MenuListItem isPressed={isPressed('entrances')} onClick={() => goToPage(`/${selectedGym?.id}/entrances`)}>
              <IconBox>{isPressed('entrances') ? <EntranceLogsPressedSvg /> : <EntranceLogsSvg />}</IconBox>
              <span>入場履歴</span>
            </MenuListItem>
          )}
          {authorityState.log !== 0 && (
            <MenuListItem isPressed={isPressed('payments')} onClick={() => goToPage(`/${selectedGym?.id}/payments`)}>
              <IconBox>{isPressed('payments') ? <PaymentLogsPressedSvg /> : <PaymentLogsSvg />}</IconBox>
              <span>支払い履歴</span>
            </MenuListItem>
          )}
          {authorityState.config !== 0 && (
            <MenuListItem isPressed={isPressed('settings')} onClick={() => goToPage(`/${selectedGym?.id}/settings`)}>
              <IconBox>{isPressed('settings') ? <SettingPressedSvg /> : <SettingSvg />}</IconBox>
              <span>設定</span>
            </MenuListItem>
          )}
        </div>
        <div>
          <Divider />
          <MenuListItem>
            <StoreSelector />
          </MenuListItem>
          <Divider />
          <MenuListItem onClick={handleLogout}>
            <span>ログアウト</span>
          </MenuListItem>
        </div>
      </MenuList>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 220px;
  border-radius: 0px 16px 0px 0px;
`;

const Title = styled.div`
  margin: 40px;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
`;

const MenuList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuListItem = styled.div<{ isPressed?: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  padding: 20px;
  display: flex;
  align-items: center;
  ${({ isPressed }) =>
    isPressed &&
    css`
      background-color: #e9e9e9;
      color: ${({ theme }) => theme.colors.primary};
    `}
  &:hover {
    /* TODO: hover時の色 */
    cursor: pointer;
  }
`;

const IconBox = styled.div`
  width: 25px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
`;

const Divider = styled.div`
  border-top: 1px solid #ced3d5;
`;
