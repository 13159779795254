import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import AnalysisSvg from '/public/gymx/analysis_1.svg';
import AnalysisPressedSvg from '/public/gymx/analysis_2.svg';
import HomeSvg from '/public/gymx/home_1.svg';
import HomePressedSvg from '/public/gymx/home_2.svg';
import UsersSvg from '/public/gymx/users_1.svg';
import UsersPressedSvg from '/public/gymx/users_2.svg';
import useGym from '@/hooks/useGym';
import { authoritySelector } from '@/store/selector/adminSelector';

export const TAB_MENU_HEIGHT = 60;

export const TabMenu = () => {
  const [currentPath, setCurrentPath] = useState<string>('');
  const router = useRouter();
  const { selectedGym } = useGym();
  const authorityState = useSelector(authoritySelector);

  const splitCount = useMemo((): number => {
    const count = Object.values({
      sale: authorityState.sale,
      userView: authorityState.userView,
      log: authorityState.log,
    }).filter((x) => x !== 0);
    return count.length + 1;
  }, [authorityState.log, authorityState.sale, authorityState.userView]);

  const isPressed = useCallback(
    (path?: string) => {
      const currentPathMenuName = currentPath.split('/')[2];
      return path === currentPathMenuName;
    },
    [currentPath],
  );

  const goToPage = (path: string) => {
    router.push(path);
  };

  useEffect(() => {
    //pathの監視
    setCurrentPath(router.pathname);
  }, [router.pathname]);

  return (
    <Container>
      <TabItem splitCount={splitCount} isPressed={isPressed()} onClick={() => goToPage(`/${selectedGym?.id}`)}>
        <IconBox>{isPressed() ? <HomePressedSvg /> : <HomeSvg />}</IconBox>
        <span>ホーム</span>
      </TabItem>
      {authorityState.sale !== 0 && (
        <TabItem
          splitCount={splitCount}
          isPressed={isPressed('sales')}
          onClick={() => goToPage(`/${selectedGym?.id}/sales`)}>
          <IconBox>{isPressed('sales') ? <AnalysisPressedSvg /> : <AnalysisSvg />}</IconBox>
          <span>売上</span>
        </TabItem>
      )}
      {authorityState.userView !== 0 && (
        <TabItem
          splitCount={splitCount}
          isPressed={isPressed('users')}
          onClick={() => goToPage(`/${selectedGym?.id}/users`)}>
          <IconBox>{isPressed('users') ? <UsersPressedSvg /> : <UsersSvg />}</IconBox>
          <span>会員一覧</span>
        </TabItem>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: ${TAB_MENU_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const TabItem = styled.div<{ isPressed?: boolean; splitCount: number }>`
  width: ${({ splitCount }) => `${100 / splitCount}%`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 9px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  ${({ isPressed }) =>
    isPressed &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.primary};
    `}
`;

const IconBox = styled.div`
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
`;
